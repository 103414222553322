import React from "react"
import ImageMeta from "../../components/ImageMeta"
import Layout from "../../components/layout"
import Seamless from "../../components/Seamless"
import { SharedStateProvider } from "../../components/SharedState/PageSharedState"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@components/Button/Button"
import ButtonGroup from "@components/Button/ButtonGroup"
import CTA from "../../components/CTA/CTA"
import SEO from "../../components/seo"
import TextPhoto from "../../components/TextPhoto/TextPhoto"
import { graphql } from "gatsby"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link } from "gatsby"
import { instructions } from "../../cms/collections/instructions"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

function OurReviews(props) {
  if (
    props.post.hearWhatTheyHaveToSay.videos.patients.youtube &&
    props.post.hearWhatTheyHaveToSay.videos.referringProviders.youtube
  ) {
    return (
      <div className="body-section reviews-block">
        <div className="columns">
          <div className="column is-4"></div>
          <div className="column" style={{ marginBottom: "2.25rem" }}>
            <h3>{props.post.hearWhatTheyHaveToSay.heading}</h3>
          </div>
          <div className="column is-4"></div>
        </div>
        <div className="columns patient-block">
          <div className="column is-2"></div>
          <div className="column">
            <TheaterVideo
              language={props.language}
              videoUrl={`https://www.youtube.com/watch?v=${props.post.hearWhatTheyHaveToSay.videos.patients.youtube}`}
              controls={true}
              playing={true}
              overVideo={true}>
              <ImageMeta
                cloudName="nuvolum"
                className="hoverable"
                responsiveUseBreakpoints
                responsive
                publicId={
                  props.post.hearWhatTheyHaveToSay.videos.patients.imageId
                }
                version="1580777124"
                width="auto"></ImageMeta>
            </TheaterVideo>
            <h5 className="image-caption">
              {props.post.hearWhatTheyHaveToSay.videos.patients.caption}
            </h5>
          </div>
          <div className="column is-2"></div>
          <div className="column">
            <TheaterVideo
              language={props.language}
              videoUrl={`https://www.youtube.com/watch?v=${props.post.hearWhatTheyHaveToSay.videos.referringProviders.youtube}`}
              controls={true}
              playing={true}
              overVideo={true}>
              <ImageMeta
                cloudName="nuvolum"
                className="hoverable"
                publicId={
                  props.post.hearWhatTheyHaveToSay.videos.referringProviders
                    .imageId
                }
                width="auto"></ImageMeta>
            </TheaterVideo>
            <h5 className="image-caption">
              {
                props.post.hearWhatTheyHaveToSay.videos.referringProviders
                  .caption
              }
            </h5>
          </div>
          <div className="column is-2"></div>
        </div>
      </div>
    )
  } else {
    return ""
  }
}

export default OurReviews
